'use client'

import { useAtomValue, useSetAtom } from 'jotai'
import { closeModalAtom, modalsAtom } from './modals.store'
import { modalDefinitions } from './modal-definitions'

/**
 * Obviously, this isn't a React portal, but the concept is the same.
 *
 * (There should only be one instance of this on the page, ever.)
 */
export function ModalPortal() {
  const modals = useAtomValue(modalsAtom)
  const modalClose = useSetAtom(closeModalAtom)

  return (
    <>
      {modals.map((modal) => {
        const ModalComponent = modalDefinitions[modal.id]
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const props: any = modal.props

        return (
          <ModalComponent
            key={modal.id}
            id={modal.id}
            {...props}
            onClose={() => {
              props?.onClose?.()
              modalClose(modal)
            }}
          />
        )
      })}
    </>
  )
}
