'use client'

import { Transition } from '@headlessui/react'
import { useHasMounted } from '@rentpath/react-hooks'
import { useAtom, useAtomValue } from 'jotai'
import { createPortal } from 'react-dom'
import styles from './toast.module.css'
import { toastAtom, toastVisibleAtom } from './toast.store'
import { ReactComponent as CloseIcon } from '@brand/icons/close.svg'

export function Toaster() {
  const toast = useAtomValue(toastAtom)
  const [isToastVisible, setIsToastVisible] = useAtom(toastVisibleAtom)
  const hasMounted = useHasMounted()

  if (!hasMounted) return null

  return createPortal(
    <Transition
      enter={styles.toastEnter}
      enterFrom={styles.toastEnterFrom}
      enterTo={styles.toastEnterTo}
      leave={styles.toastLeave}
      leaveFrom={styles.toastLeaveFrom}
      leaveTo={styles.toastLeaveTo}
      show={isToastVisible}
      className={styles.toast}
    >
      <button
        onClick={() => setIsToastVisible(false)}
        className={styles.toastButton}
      >
        <div className={styles.toastMessageBorder}></div>
        <p className={styles.toastMessage}>{toast?.message}</p>
        <CloseIcon className={styles.toastIcon} />
      </button>
    </Transition>,
    document.body
  )
}
