'use client'

import { useHydrateAtoms } from 'jotai/utils'
import type { LeadCookieData } from './lead-cookie-parser'
import { defaultLeadCookieData, leadCookieAtom } from './lead-cookie.store'

export function HydrateLeadCookie({
  leadCookieData,
}: {
  leadCookieData?: LeadCookieData
}) {
  useHydrateAtoms([[leadCookieAtom, leadCookieData || defaultLeadCookieData]])

  return null
}
