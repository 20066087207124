import { yieldOrContinue } from 'main-thread-scheduling'
import { useEffect } from 'react'
import { useInView } from 'react-intersection-observer'

export function useScrollTrack(sectionName: string, itemName: string) {
  const [intersectionRef, inView] = useInView({
    triggerOnce: true,
  })

  useEffect(() => {
    async function trackScroll() {
      if (inView) {
        await yieldOrContinue('idle')
        window.eventTracker?.track('scroll', {
          item: itemName,
          non_interaction: true,
          section: sectionName,
        })
      }
    }

    void trackScroll()
  }, [inView, itemName, sectionName])

  return intersectionRef
}
