/**
 * NOTE: THIS IS AN AUTO-GENERATED FILE. DO NOT MODIFY IT DIRECTLY.
 */

/* eslint-disable */

import type * as Types from '../../../__generated__/api-types.js';

import { GraphqlRequester, GraphqlRequesterOptions } from "@rentpath/graphql-requester";
import { User_UserFragmentDoc } from './user-fields.gql';
export type UserQueryVariables = Types.Exact<{
  zutronId: Types.Scalars['ID'];
}>;


export type UserQuery = { user: { id?: string | null, givenName?: string | null, familyName?: string | null, email?: string | null, savedListingsIds: Array<string>, zutronId: string, savedSearches: Array<{ id: number, name: string, notificationEmail: boolean, notificationPush: boolean, searchUrl: string }> } };


export const UserDocument = `
    query User($zutronId: ID!) {
  user(zutronId: $zutronId) {
    ...User_UserFragment
  }
}
    ${User_UserFragmentDoc}`;

    export function createUserFetcher(options: GraphqlRequesterOptions) {
            const graphqlRequester = new GraphqlRequester(options)
    
            return function fetchUser(variables: UserQueryVariables, headers?: RequestInit['headers']) {
      return graphqlRequester.request<UserQuery, UserQueryVariables>(UserDocument, variables, headers);
      }
    }

