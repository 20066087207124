/**
 * NOTE: THIS IS AN AUTO-GENERATED FILE. DO NOT MODIFY IT DIRECTLY.
 */

/* eslint-disable */

import type * as Types from '../../../__generated__/api-types.js';

import { GraphqlRequester, GraphqlRequesterOptions } from "@rentpath/graphql-requester";
import { User_UserFragmentDoc } from './user-fields.gql';
export type AnonymousUserQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type AnonymousUserQuery = { userAnonymous: { id?: string | null, givenName?: string | null, familyName?: string | null, email?: string | null, savedListingsIds: Array<string>, zutronId: string, savedSearches: Array<{ id: number, name: string, notificationEmail: boolean, notificationPush: boolean, searchUrl: string }> } };


export const AnonymousUserDocument = `
    query AnonymousUser {
  userAnonymous {
    ...User_UserFragment
  }
}
    ${User_UserFragmentDoc}`;

    export function createAnonymousUserFetcher(options: GraphqlRequesterOptions) {
            const graphqlRequester = new GraphqlRequester(options)
    
            return function fetchAnonymousUser(variables?: AnonymousUserQueryVariables, headers?: RequestInit['headers']) {
      return graphqlRequester.request<AnonymousUserQuery, AnonymousUserQueryVariables>(AnonymousUserDocument, variables, headers);
      }
    }

