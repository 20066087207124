/**
 * NOTE: THIS IS AN AUTO-GENERATED FILE. DO NOT MODIFY IT DIRECTLY.
 */

/* eslint-disable */

import type * as Types from '../../../__generated__/api-types.js';

import { GraphqlRequester, GraphqlRequesterOptions } from "@rentpath/graphql-requester";
export type User_UserFragment = { id?: string | null, givenName?: string | null, familyName?: string | null, email?: string | null, savedListingsIds: Array<string>, zutronId: string, savedSearches: Array<{ id: number, name: string, notificationEmail: boolean, notificationPush: boolean, searchUrl: string }> };

export const User_UserFragmentDoc = `
    fragment User_UserFragment on User {
  id
  givenName
  familyName
  email
  savedListingsIds
  zutronId
  savedSearches {
    id
    name
    notificationEmail
    notificationPush
    searchUrl
  }
}
    `;



