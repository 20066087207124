import { useEffect, useState } from 'react'
import { BackDrop } from '../../components/back-drop/back-drop'
import { Spinner } from '../../components/spinner/spinner'
import styles from './modal-loading-indicator.module.css'

export function ModalLoadingIndicator() {
  const [shouldRender, setShouldRender] = useState(false)

  useEffect(() => {
    const speed = window.navigator?.connection?.effectiveType
    const timeout = speed && speed === '4g' ? 150 : 0
    // avoid flashing on fast connections
    const timer = setTimeout(() => setShouldRender(true), timeout)

    return () => clearTimeout(timer)
  }, [])

  return shouldRender ? (
    <div className={styles.root}>
      <div className={styles.spinner}>
        <Spinner />
      </div>

      <BackDrop isVisible />
    </div>
  ) : null
}
