import { useAtom } from 'jotai'
import Cookies from 'js-cookie'
import { useCallback, useEffect, useState } from 'react'
import type { GraphQLError } from 'graphql'
import { graphqlRequesterOptions } from '../../config/graphql-requester-options'
import { createAnonymousUserFetcher } from './__generated__/anonymous-user.gql'
import { createUserFetcher } from './__generated__/user.gql'
import { ZID_KEY } from './user.const'
import { userAtom } from './user.store'

const fetchAnonymousUser = createAnonymousUserFetcher(graphqlRequesterOptions)
export const fetchUser = createUserFetcher(graphqlRequesterOptions)

type FetchUserArgs = {
  autofetchAnonymousUser?: boolean
}

export function useFetchUser({ autofetchAnonymousUser = true }: FetchUserArgs) {
  const [_, setUser] = useAtom(userAtom)
  const [zutronIdCookie, setZutronIdCookie] = useState<string | null>(null)
  const setAnonymousUser = useCallback(() => {
    fetchAnonymousUser().tapOk((result) => {
      const zutronId = result.userAnonymous.zutronId

      if (zutronId) {
        Cookies.set(ZID_KEY, zutronId, { path: '/' })
        setZutronIdCookie(zutronId)
      }
    })
  }, [setZutronIdCookie])

  useEffect(() => {
    const zidCookie = Cookies.get(ZID_KEY)

    if (!zidCookie && autofetchAnonymousUser) {
      setAnonymousUser()
    } else if (zidCookie) {
      setZutronIdCookie(zidCookie)
    }
  }, [setZutronIdCookie, setAnonymousUser, autofetchAnonymousUser])

  const getUser = useCallback(
    async (zidFromRequest?: string) => {
      if (zidFromRequest || zutronIdCookie) {
        return await fetchUser({
          zutronId: zidFromRequest || zutronIdCookie || '',
        })
          .tapOk((result) => setUser(result.user))
          .tapError((error: GraphQLError) => {
            // Note: Need to refresh "zutronId" on 404 error
            if (error.message.match('404')) {
              setAnonymousUser()
            }
          })
      }
    },
    [zutronIdCookie, setUser, setAnonymousUser]
  )

  return getUser
}
