'use client'

import { useEffect } from 'react'
import type { RequestData } from '../request-data/request-data.const'

export function LogRequestData(props: { requestData: RequestData }) {
  useEffect(() => {
    /* eslint-disable no-console */
    console.groupCollapsed('[Unified Web Request Data]')
    console.log(props.requestData)
    console.groupEnd()
  }, [props.requestData])

  return null
}
