'use client'

import { useEffect } from 'react'
import { useFetchUser } from './use-fetch-user'

export function FetchUser() {
  const fetchUser = useFetchUser({})
  useEffect(() => {
    void fetchUser()
  }, [fetchUser])

  return null
}
