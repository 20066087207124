'use client'

import { useScrollTrack } from './use-scroll-track'

type ScrollTrackProps = {
  as?: React.ElementType
  sectionName: string
  itemName: string
  children: React.ReactNode
} & React.HTMLAttributes<HTMLElement>

/**
 * If you need to track scroll depth on a specific element:
 * - server component: use this `<ScrollTrack />` component as your tracking element
 * - client component: use `useScrollTrack` directly and attach the ref it returns to a ReactElement
 */
export function ScrollTrack({
  as,
  sectionName,
  itemName,
  children,
  ...divAttrs
}: ScrollTrackProps) {
  const intersectionRef = useScrollTrack(sectionName, itemName)
  const Comp = as || 'div'

  return (
    <Comp ref={intersectionRef} {...divAttrs}>
      {children}
    </Comp>
  )
}
