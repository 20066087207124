import { atom } from 'jotai'
import { TOAST_ANIMATION_LEAVE_DURATION } from './toast.const'

type Toast = {
  message: string
  duration: number
}

export const toastAtom = atom<Toast | null>(null)

export const toastVisibleAtom = atom(false)

const toastTimeoutAtom = atom<NodeJS.Timeout | null>(null)

export const showToastAtom = atom(null, (get, set, update: Toast) => {
  const existingTimeout = get(toastTimeoutAtom)

  if (existingTimeout) {
    clearTimeout(existingTimeout)
  }

  const timeout = setTimeout(() => {
    set(toastVisibleAtom, false)

    setTimeout(() => {
      set(toastAtom, null)
    }, TOAST_ANIMATION_LEAVE_DURATION)
  }, update.duration)

  set(toastAtom, update)
  set(toastVisibleAtom, true)
  set(toastTimeoutAtom, timeout)
})
